import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';
import { SharedService } from 'src/app/shared.service';
import { EditMeetingComponent } from './edit-meeting/edit-meeting.component';
import { EditInviteesComponent } from './edit-invitees/edit-invitees.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, startWith, timeout } from 'rxjs';
// import {ConvertAPI} from 'convertapi';

export interface meetingdetails{
  meeting_title: "";
  start_date:"";
  end_date: "";
  meeting_address: "";
  invitees: [''];
  isActive: false;
}

@Component({
  selector: 'app-meeting-creator',
  templateUrl: './meeting-creator.component.html',
  styleUrls: ['./meeting-creator.component.scss'],
})
export class MeetingCreatorComponent implements OnInit {
  formGroup: FormGroup;
  inviteeformGroup: FormGroup;
  agendaformGroup: FormGroup;
  loadingCompliance = false;
  loadingChecks = false;
  agendaForm: FormGroup;
  sendEmail: false;
  item: any = '';
  level: any = 0;
  i_parent: any = '';
  i_item_a: any = '';
  i_item_b: any = '';
  allusers : any[] = []
  
  allowpublish = false
  editItem: any = {};
  users: any = [];
  meeting: any = null;
  agendaFormUpdate: any = '';
  itemsJson: any = {};
  published : boolean = false
  meetingcreated : boolean = false
  inviteescreated : boolean = false
  currentuser : any
  sessionStorage = window.sessionStorage;

  groupInvitees: any = new FormControl([]);

  constructor(
    public _location: Location,
    private fb: FormBuilder,
    private apiManager: ApiManagerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.currentuser = JSON.parse(this.sessionStorage.getItem('profile'));
    this.getUsers();
    this.createMeeetingForm();
    this.createAgendaForm();
    // this.getallusers()
    
  }
  publishmeeting(meetingObj){
    console.log("first obj", meetingObj)
    const spinner = this.apiManager.startLoading('Updating meeting..');
    meetingObj.isActive = true
    
    console.log(meetingObj)
    this.apiManager.updateMeetingStatus(meetingObj.id, meetingObj).subscribe({
      next: (res: any) => {
        this.apiManager.stopLoading(spinner);
        this.meeting = res
        console.log("meeting is ----", this.meeting)
        this.router.navigate(['admin/meeting-page/draft']);
        this.openSnackBar('Meeting Published successfully', 'Close');

        
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        console.log(err);
        this.openSnackBar('Did not publish meeting', 'Close');
      },
    })
  }


  // getallusers(){
  //   this.apiManager.getAllUsers().subscribe({
  //     next: (res: any) => {
  //       console.log("users are stored in banks --", res)
  //       this.allusers = res;
        
  //     },
  //   });
  // }

  createMeeetingForm() {
    this.formGroup = this.fb.group({
      meeting_title: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      meeting_address: ['', [Validators.required]],
      invitees: [[this.currentuser.id],],
      isActive: [false, [Validators.required]],
    });
    this.inviteeformGroup = this.fb.group({
      invitees: [''],
      // isActive: [true, [Validators.required]],
    });
  }

  EditInvitees(meeting){
    console.log("meeting", meeting)
    var data ={
      meeting: meeting,
      users : this.users
    } 
    const dialogRef = this.dialog.open(EditInviteesComponent, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        this.meeting = result
        const path = '/admin/meeting-creation-page';
        const param = {
          meeting: this.sharedService.encryptData(result),
        };
        this.sharedService.navigaTo(path, param);
      }
    });
  }

  createInvitees(data){
    // this.inviteescreated = true
    // console.log("data is --", data.invitees)
    // this.meeting.invitees = data.invitees
    const spinner = this.apiManager.startLoading('Updating meeting..');
    this.apiManager.updateMeeting(this.meeting.id , data).subscribe({
      next: (res : any)  => {
        console.log(res)
        this.meeting = res
        this.inviteescreated = true
        this.apiManager.stopLoading(spinner);
        this.openSnackBar('Meeting invitees updated successfully!', 'Close');
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        this.openSnackBar('Oops! Error while updating meeting!', 'Close');
      },

      
    })
    
  }

  onSubmitMeeting(data) {
    // var invitess = this.groupInvitees.value.concat(
    //   data['invitees'].filter(
    //     (item) => this.groupInvitees.value.indexOf(item) < 0
    //   )
    // );

    // data['invitees'] = invitess;
    // data['sendEmail'] = true;

    // if (data['invitees'].length > 0) {
    console.log("data is", data)
    if (this.meeting?.id) {
      const spinner = this.apiManager.startLoading('Updating meeting..');
      this.apiManager.updateMeeting(this.meeting?.id, data).subscribe({
        next: (res) => {
          this.apiManager.stopLoading(spinner);
          this.openSnackBar('Meeting updated successfully', 'Close');

          
          // this.allowpublish = true
        },
        error: (err) => {
          this.apiManager.stopLoading(spinner);
          this.openSnackBar('Oops! Error while updating meeting!', 'Close');
        },
      });
    } else {
      console.log("data is", data)
      data['created_by'] = this.currentuser?.id
      const spinner = this.apiManager.startLoading(
        'Saving meeting details..'
      );
      this.apiManager.createMeeting(data).subscribe({
        next: (res: any) => {
          console.log("response meeting created is", res)
          
          if (res?.status === 'Failed') {
            // this.meeting = res.message_object;
            this.apiManager.stopLoading(spinner);
            this.openSnackBar(res.message, 'Close');
            return
          } else {
            this.meeting = res;
            this.meetingcreated = true

            this.apiManager.stopLoading(spinner);
            this.openSnackBar('Meeting created successfully. ', 'Close');
            this.allowpublish = true
            this.createAgendaForm();

            this.viewMeetingDetails(this.meeting)
          }
          
        },
        error: (err) => {
          this.apiManager.stopLoading(spinner);
          this.openSnackBar('Oops! Something went wrong.  ', 'Close');
          console.log(err);
        },
      });
    }
    // } else alert('Invitees are required');
  }

  romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = '',
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }
  leterize(num) {
    var letter = String.fromCharCode(num + 64);
    return letter;
  }

  getUsers() {
    this.apiManager
      .getAllUsers()
      .subscribe({ next: (res) => (this.users = res) });
  }

  createAgendaForm() {
    this.agendaForm = this.fb.group({
      meeting_id: [this.meeting?.id, [Validators.required]],
      items: this.fb.array([]),
    });
    this.addItem();
  }

  setActiveItem(level, i_parent, i_item_a, i_item_b) {
    this.level = level;
    this.i_parent = i_parent;
    this.i_item_a = i_item_a;
    this.i_item_b = i_item_b;
  }
  addSubSection(level) {
    if (level == 1) {
      this.addSecondLevelItem(this.i_parent);
    } else if (level == 2) {
      this.addThirdLevelItem(this.i_parent, this.i_item_a);
    }
    this.level = 0;
  }

  deleteSection(level) {
    if (level == 1) {
      this.removeItem(this.i_parent);
    } else if (level == 2) {
      this.removeSecondLevelItem(this.i_parent, this.i_item_a);
    } else if (level == 3) {
      this.removeThirdLevelItem(this.i_parent, this.i_item_a, this.i_item_b);
    }
    this.level = 0;
  }

  addItem() {
    (this.agendaForm.get('items') as FormArray).push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        document_names : [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
        level_a_items: this.fb.array([]),
      })
    );
  }
  removeItem(i) {
    (this.agendaForm.get('items') as FormArray).removeAt(i);
    this.item = '';
  }

  addSecondLevelItem(i_parent) {
    console.log(i_parent);
    const options = (this.agendaForm.get('items') as FormArray).controls[
      i_parent
    ].get('level_a_items') as FormArray;
    console.log(options);

    options.push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
        level_b_items: this.fb.array([]),
      })
    );
  }

  removeSecondLevelItem(i_item, index) {
    (
      (<FormArray>this.agendaForm.controls['items'])
        .at(i_item)
        .get('level_a_items') as FormArray
    ).removeAt(index);
  }

  addThirdLevelItem(i_item, index) {
    const options = (
      (this.agendaForm.get('items') as FormArray).controls[i_item].get(
        'level_a_items'
      ) as FormArray
    ).controls[index].get('level_b_items') as FormArray;

    options.push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
      })
    );
  }
  removeThirdLevelItem(i_item, index, i) {
    const item = (
      (<FormArray>this.agendaForm.controls['items'])
        .at(i_item)
        .get('level_a_items') as FormArray
    )
      .at(index)
      .get('level_b_items') as FormArray;
    item.removeAt(i);
  }

  submitAgendaItems(data) {
    this.itemsJson = data;
    const spinner = this.apiManager.startLoading(
      'Saving meeting agenda items....'
    );
    this.apiManager.createAgenda(data).subscribe({
      
      next: (res) => {
        console.log("Agenda");
        this.apiManager.stopLoading(spinner);
        this.openSnackBar(
          'Agenda items attached to the meeting successfully. Redirecting to drafts to continue editing',
          'Close'
        );

        setTimeout(() => {
          console.log("waiting 4 seconds")
          this.router.navigate(['/admin/meeting-page/draft']);
        }, 4000);
        
        // /admin/meeting-page/draft
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        this.openSnackBar('Something went wrong. ', 'Close');

        setTimeout(() => {
          console.log("waiting 4 seconds")
          location.reload();
        }, 4000);
      },
    });

    this.agendaForm.reset();
    // location.reload();
  }

  viewMeetingDetails(meeting: any) {
    console.log("meeting is",meeting.isActive)
    this.sharedService.meetingObj.next([meeting]);
    if(meeting.isActive == false){
      var path = '/admin/meeting-draft-page';
    }else {
      var path = '/admin/meeting-view-page';
    }
    
    const param = {
      meeting: this.sharedService.encryptData(meeting),
    };
    this.sharedService.navigaTo(path, param);
  }

  remoteMeetingDetails(): void {
    const data = this.meeting;
    if (data !== null) {
      const dialogRef = this.dialog.open(DialogRemoteMeetingDialog, {
        restoreFocus: false,
        width: '500px',
        panelClass: ['animate__animated', 'animate__fadeInRight'],
        height: '100vh',
        position: {
          bottom: '0px',
          right: '0px',
        },
        data: data,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
  }

  editItemDetailsDialog(i_item, level = 0, a_item_index, b_item_index): void {
    if (level == 0) {
      this.editItem = this.agendaForm.value.items[i_item];
      console.log("level 0")
      console.log("items to edit are, ", this.editItem)

      this.agendaFormUpdate = (<FormArray>this.agendaForm.get('items')).at(
        i_item
      );
    } else if (level == 1) {
      this.editItem =
        this.agendaForm.value.items[i_item].level_a_items[a_item_index];

      console.log("level 1")
      console.log("items to edit are, ", this.editItem)

      this.agendaFormUpdate = (
        (<FormArray>this.agendaForm.controls['items'])
          .at(i_item)
          .get('level_a_items') as FormArray
      ).at(a_item_index);
    } else if (level == 2) {
      this.editItem =
        this.agendaForm.value.items[i_item].level_a_items[
          a_item_index
        ].level_b_items[b_item_index];

      this.agendaFormUpdate = (
        (
          (<FormArray>this.agendaForm.controls['items'])
            .at(i_item)
            .get('level_a_items') as FormArray
        )
          .at(a_item_index)
          .get('level_b_items') as FormArray
      ).at(b_item_index);
    }
    const data = {
      item: this.editItem,
      users: this.meeting?.invitees,
    };

    const dialogRef = this.dialog.open(DialogEditAgendaDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.agendaFormUpdate.patchValue({
          agenda_name: result.agenda_name,
          agenda_description: result.agenda_description,
          agenda_document: result.agenda_document,
          document_names : result.document_names,
          permission: result.permission,
          presenters: result.presenters,
          guests: result.guests,
        });
      }
    });
  }

  EditMeeting(meeting) {
    console.log("meeting", meeting)
    var data ={
      meeting: meeting,
    } 
    const dialogRef = this.dialog.open(EditMeetingComponent, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        this.meeting = result
        const path = '/admin/meeting-creation-page';
        const param = {
          meeting: this.sharedService.encryptData(result),
        };
        this.sharedService.navigaTo(path, param);
      }
    });
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}

@Component({
  selector: 'dialog-edit-agenda-dialog',
  templateUrl: 'dialog-edit-agenda-dialog.html',
  styleUrls: ['./meeting-creator.component.scss'],
})
export class DialogEditAgendaDialog {
  formGroup: FormGroup;
  users: any = [];
  stringusers : any = []
  agenda: any = {};
  fileUrl = '';
  filearray: string[] = [];
  files: File[] = [];
  loading = false;
  title = '';
  guestslist : Observable<any[]>
  presenterslist : Observable<any[]>
  guests : any[] = []
  presenters : any[] = []
  document_names : any[] = []

  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  presentersControl = new FormControl('');
  guestsControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogEditAgendaDialog>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService
  ) {}

  ngOnInit() {
    this.users = this.data.users;
    this.getuserstrings()
    
    this.agenda = this.data.item;
    console.log("agenda is ", this.agenda)
    this.formGroup = this.formBwilder.group({
      agenda_name: [this.agenda?.agenda_name || []],
      agenda_description: [this.agenda?.agenda_description || []],
      agenda_document: [this.agenda?.agenda_document || []],
      permission: [this.agenda?.permission || []],
      presenters: [this.agenda?.presenters || []],
      guests: [this.agenda?.guests || []],
      document_names : [ this.agenda?.document_names || []],
    });
    this.presenterslist = this.stringusers
    this.guestslist = this.stringusers
    console.log("presenters list is ", this.presenterslist)
    console.log("guestslist is ", this.guestslist)

    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );
    this.presenterslist = this.presentersControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.guestslist = this.guestsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter2(value || '')),
    );

    console.log("presenters list is ", this.presenterslist)
    console.log("guestslist is ", this.guestslist)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.stringusers.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filter2(value2: string): string[] {
    const filterValue2 = value2.toLowerCase();

    return this.stringusers.filter(option2 => option2.toLowerCase().includes(filterValue2));
  }
  getuserstrings(){
    for(let x = 0; x < this.users.length; x+=1){
      let firstname = this.users[x].first_name
      let lastname = this.users[x].last_name
      this.stringusers.push(firstname+" "+lastname)
    }
    console.log("string users are", this.stringusers)
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(data: any) {
    
    data['guests'] = this.guests
    this.agenda.guests = this.guests
    data['presenters'] = this.presenters
    this.agenda.presenters = this.presenters
    data['document_names'] = this.document_names
    this.agenda.document_names = this.document_names
    console.log("data is --- ", data)
    if (this.fileUrl) data['agenda_document'] = this.filearray;
    console.log("Agenda Created");
    this.dialogRef.close(data);
  }
  addguest(guest){
    console.log("guest is --------",guest)
    this.guests.push(guest)
    console.log("guests after adding", this.guests)
  }
  onRemoveGuest(guest){
    this.guests.splice(this.guests.indexOf(guest), 1)
    console.log("guests after removing", this.guests)
  }
  addpresenter(guest){
    console.log("guest is --------",guest)
    this.presenters.push(guest)
    console.log("guests after adding", this.presenters)
  }
  onRemovePresenter(guest){
    this.presenters.splice(this.presenters.indexOf(guest), 1)
    console.log("guests after removing", this.presenters)
  }

  //add attachment
  // onSelect(event: any) {
  //   this.files.push(...event.addedFiles);
  //   if (this.files[0]) {
  //     const file = this.files[0];

  //     this.changeFile(file).then((fileBlob: string): any => {
  //       this.fileUrl = fileBlob;
  //     });
  //   }
  //   return;
  // }

  onSelect(event: any) {
    console.log("added files are --"+event.addedFiles[0].name)
    this.files.push(...event.addedFiles);
    this.document_names = []
    console.log("files are"+this.files[0]);
    // const fileblob = ''
    
    // this.changeFile(this.files[0]).then((fileBlob: string): any => {
    //   this.fileUrl = fileBlob;
    //   this.filearray.push(this.fileUrl)
    //   console.log("file url is ----"+this.fileUrl)
    //   console.log(`file array is ${this.filearray}`)
    // });

    // console.log("converted blob is"+this.fileUrl);

    

    
    this.files.forEach((value, index) => {
      console.log(`index is ${index}`)
      console.log(`value is ${value.name}`)
      const file = this.files[index];
      console.log("files variable --"+this.files[index].name)
      
      this.document_names.push(this.files[index].name)
      this.changeFile(file).then((fileBlob: string): any => {
        this.fileUrl = fileBlob;
        this.filearray.push(this.fileUrl)
        console.log("file url is ----"+this.fileUrl)
        if (this.fileUrl.includes("application/msword")) {
          console.log("this is a word doc");
          console.log("converting word to pdf")
          // const convertapi = new ConvertAPI('FqoKgkx3jrqhG0x3');
          // convertapi.convert('pdf', { File: file })
          //   .then(function(result) {
          //     // get converted file url
          //     console.log("Converted file url: " + result.file.url);

          //     // save to file
          //     return result.file.save('/path/to/save/file.pdf');
          //   })
          //   .then(function(file) {
          //     console.log("File saved: " + file);
          //   })
          //   .catch(function(e) {
          //     console.error(e.toString());
          //   });

        }
        if (this.fileUrl.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")){
          console.log("this is a word docx");
        }
        console.log(`file array is ${this.filearray}`)
      });
    }
    )
    console.log("document names are", this.document_names)
    
    
    return;
  }
  //change to blob data
  changeFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  //remove attachment
  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.fileUrl = '';
    this.document_names.splice(this.files.indexOf(event.name), 1)

    console.log("documents after deleting are",this.document_names )
  }
}

@Component({
  selector: 'dialog-setup-remote-dialog',
  templateUrl: 'dialog-setup-remote-dialog.html',
  styleUrls: ['./meeting-creator.component.scss'],
})
export class DialogRemoteMeetingDialog {
  formGroup: FormGroup;
  zoomObj: any = [];
  loading = false;
  title = '';
  numbers = [20, 30, 40, 45];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogEditAgendaDialog>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.formGroup = this.formBwilder.group({
      meeting_id: [this.data?.id, [Validators.required]],
      zoom_topic: [this.data?.meeting_title, [Validators.required]],
      start_time: ['', [Validators.required]],
      duration: ['', [Validators.required]],
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    this.apiManager.generateZoomMeeting(data).subscribe(
      (response: any) => {
        this.loading = false;
        this.zoomObj = response?.data;
        this.openSnackBar(response?.message, 'CLOSE');
        //this.dialogRef.close();
      },
      (err) => {
        this.loading = false;
        console.log(err);
        this.openSnackBar(err?.message, 'CLOSE');
      }
    );
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
