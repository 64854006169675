import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { DOCUMENT, Location } from '@angular/common';
import { Fade } from '../animations';
import { ApiManagerService } from './../api-manager/api-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LicensesService } from '../services/licenses.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { ManageTaskDialog } from '../tasks-page/tasks-page.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  sessionStorage = window.sessionStorage;

  docView : any
  socialObj: any = [];
  organizationsObj: any = [];
  contactsObj: any = [];
  leadersObj: any = [];
  org_reference_key = '';
  currentUser: any = {};
  meetingsObj: any = [];
  allTasksObj: any = [];
  users: any = [];
  contractsObject: any = []
  loadData : boolean = true
  globalsearches : any = []
  value = ''
  agenda_documents_results : any[] = []
  meeting_results : any[] = []
  voting_questions : any[]= []
  surveys : any[] = []
  signatures : any[] = []
  all_compliance : any[] = []
  tasks : any[] = []
  signatureObjList : any[] = [];
  contractObjList : any[] = []
  allowsigning : any;

  constructor(
    public dialogRef: MatDialogRef<ManageTaskDialog>,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public sharedService: SharedService,
    private apiManager: ApiManagerService,
    private snackBar: MatSnackBar,
    private licenseService: LicensesService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
    // console.log("current user is ---",this.currentUser)
    // this.org_reference_key = this.currentUser?.org_reference_key;
    // console.log("organisation reference key is ,",this.org_reference_key)
    // this.sessionStorage.setItem('load', 'reload');

    this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
    this.org_reference_key = this.currentUser?.org_reference_key;

    if (this.sessionStorage.getItem('load')) {
      this.getOrganizationDetails();
      this.getSocialMedia();
      this.getContacts();
      this.getLeadershipMembers();
      this.getActiveMeetings();
      this.getTasks();
      this.getContracts();
      this.getAllUsers();
    } else {
      this.sessionStorage.setItem('load', 'reload');
      location.reload();
    }
    
  }

  ngAfterViewInit() {

    // this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
    // this.org_reference_key = this.currentUser?.org_reference_key;

    // if (this.sessionStorage.getItem('load')) {
    //   this.getOrganizationDetails();
    //   this.getSocialMedia();
    //   this.getContacts();
    //   this.getLeadershipMembers();
    //   this.getActiveMeetings();
    //   this.getTasks();
    //   this.getAllUsers();
    // } else {
    //   this.sessionStorage.setItem('load', 'reload');
    //   location.reload();
    // }
    // const spinner = this.apiManager.startLoading('Loading dashboard....');

    // setTimeout(()=>{
    //   this.getOrganizationDetails();
    //   this.getSocialMedia();
    //   this.getContacts();
    //   this.getLeadershipMembers();
    //   this.getActiveMeetings();
    //   this.getTasks();
    //   this.getAllUsers();
    //   this.apiManager.stopLoading(spinner);
    // }, 5000)

    
    

  }
  getAllUsers() {
    this.apiManager.getAllUsers().subscribe({
      next: (res) => (this.users = res),
    });
  }

  getActiveMeetings() {
    this.apiManager.getActiveMeetings().subscribe(
      (response: any) => {
        this.meetingsObj = response;
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  selectsearch(street : any ){
    console.log("street is ", street)
  }
  applyFilter(event : any){
    console.log("event is ", event.target.value)
    this.value = event.target.value

    const searchvalue = {
      "searchvalue" : this.value
    }
    // (event.target as HTMLInputElement).value
    this.apiManager.getSearch(searchvalue).subscribe(
      (response: any) => {
        // this.contractsObject = response;
        // this.agenda_documents_results = response?.agenda_documents_results
        this.meeting_results = response?.meeting_results
        this.voting_questions = response?.voting_questions
        this.surveys = response?.surveys
        this.signatures = response?.signatures
        this.all_compliance = response?.all_compliance
        this.tasks = response?.tasks
        this.signatureObjList = response?.signatureslist
        this.contractObjList = response?.contract_list
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );

    // this.globalsearches
  }


  openContract(contract : any){
    const path = '/admin/contract-execution-page';
    const param = {
      contract: this.sharedService.encryptData(contract ),
    };
    this.sharedService.navigaTo(path, param);
  }

  getContracts() {
    this.apiManager.getContracts().subscribe(
      (response: any) => {
        this.contractsObject = response;
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  viewSearchMeetingDetails(meeting: any) {
    console.log("meeting is",meeting.isActive)
    this.sharedService.meetingObj.next([meeting]);
    if(meeting.isActive == false){
      var path = '/admin/meeting-draft-page';
    }else {
      var path = '/admin/meeting-view-page';
    }
    
    const param = {
      meeting: this.sharedService.encryptData(meeting),
    };
    this.sharedService.navigaTo(path, param);
  }

  viewVotingDetail(data: any) {
    const path = '/admin/voting-page';
    const param = {
      voting: this.sharedService.encryptData(data),
    };
    this.sharedService.navigaTo(path, param);
  }
  // Edit company details function

  openSurveyObjView(obj: any) {
    const path = '/admin/survey-view';
    const param = {
      survey: this.sharedService.encryptData(obj),
    };
    this.sharedService.navigaTo(path, param);
  }

  openBook(meetingObj){
    console.log("meeting id for openbook",meetingObj.id)
   
    this.router.navigate(['admin/meeting-openbook'], {state: {meeting_id : meetingObj.id, meeting : meetingObj}});
  }

  manageTaskDialog(task: any): void {
    
    const dialogRef = this.dialog.open(ManageTaskDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__slideInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: task,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result === 'doc') {
          this.docView = true;
        } else ;
      }
    });
  }

  openSignature(signature) {
    const path = '/admin/signature-view-page';
    for(let x = 0; x < this.signatureObjList.length; x+=1){
      console.log("looping though signature list", this.signatureObjList[x])
      if(this.signatureObjList[x].id == signature.id){
        console.log("found esignature", this.signatureObjList[x].Signing_Open )
        this.allowsigning = this.signatureObjList[x].Signing_Open
      }
    }
    const param = {
      signature: this.sharedService.encryptData(signature),
      signdoc : this.allowsigning,
    };
    this.sharedService.navigaTo(path, param);
  }

  openEditDetailsDialog(){
    const dialogRef = this.dialog.open(EditDetailsComponent, {
      data: {orgdetails : this.organizationsObj },
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result is :" , result);
        this.organizationsObj[0] = result
        // const path = '/admin/meeting-view-page';
        // const param = {
        //   meeting: this.sharedService.encryptData(result),
        // };
        // this.sharedService.navigaTo(path, param);
      }
    });
  }

  openCompliance(compliance) {
    const path = '/admin/compliance-view';
    const param = {
      compliance: this.sharedService.encryptData(compliance),
    };
    this.sharedService.navigaTo(path, param);
  }

  getTasks() {
    // this.apiManager.
    const user_id = this.currentUser?.id;
    this.apiManager.getTaskByAssignee(user_id).subscribe(
      (response: any) => {
        this.allTasksObj = response;
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );


    // this.apiManager.getAllTasks().subscribe(
    //   (response: any) => {
    //     this.allTasksObj = response;
    //     console.log(response);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }


  // getTaskByAssignee() {
  //   const user_id = this.currentUser?.id;
  //   this.apiManager.getTaskByAssignee(user_id).subscribe(
  //     (res: any) => {
  //       this.assignedTaskObj = res;
  //       console.log(this.assignedTaskObj);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  getOrganizationDetails() {
    this.licenseService
      .getOrganizationByOrRegNo(this.org_reference_key)
      .subscribe({
        next: (res) => {
          console.log("organisation object is :", res);
          this.organizationsObj = res;
        },
      });
  }

  getLeadershipMembers() {
    this.licenseService
      .getOrganizationLeaders(this.org_reference_key)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.leadersObj = res;
        },
      });
  }
  getContacts() {
    //const spiner = this.apiManager.startLoading('Setting up...');
    this.licenseService
      .getOrganizationContact(this.org_reference_key)
      .subscribe({
        next: (res) => {
          this.contactsObj = res;
          //this.apiManager.stopLoading(spiner);
        },
        error: (err) => {
          //this.apiManager.stopLoading(spiner)
        },
      });
  }
  getSocialMedia() {
    this.licenseService
      .getOrganizationSocialMedia(this.org_reference_key)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.socialObj = res;
        },
      });
  }

  viewMeetingDetails(meeting: any) {
    const path = '/admin/meeting-view-page';
    const param = {
      meeting: this.sharedService.encryptData(meeting),
    };
    this.sharedService.navigaTo(path, param);
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
