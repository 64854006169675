import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from 'ng2-charts';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';
import { SharedService } from 'src/app/shared.service';
import { DialogEditAgendaDialog, DialogRemoteMeetingDialog } from '../meeting-creator.component';
import { forkJoin, Observable } from 'rxjs';
import { DialogAttandanceDialog, DialogEditAgendaItemDialog, DialogEditMeetingDialog, agendaitem } from '../../meeting-view/meeting-view.component';
import { EditInviteesComponent } from '../edit-invitees/edit-invitees.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-draft',
  templateUrl: './edit-draft.component.html',
  styleUrls: ['./edit-draft.component.css']
})
export class EditDraftComponent implements OnInit {

  sessionStorage = window.sessionStorage;
  agendaformGroup: FormGroup;
  agendaFormUpdate: any = '';
  meetingObj: any = {};
  items: any = [];
  item: any = '';
  users: any = [];
  user_ids: any = [];
  currentUser: any = {};
  usersRsvp: any = [];
  minutesObj: any = [];
  rsvps: any = [];
  user_id: any = '';
  meeting_id: any = '';
  viewAgendaDoc = false;
  analytics: any = {};
  zoomObj: any = {};
  agendaForm: FormGroup;
  agendaDoc : any
  sendEmail = false;
  editItem: any = {};
  showZoomWindow = false;
  itemsJson: any = {};
  dragPosition = { x: 0, y: 0 };
  agendakeys : any[] = []
  agendadocuments : any[] = []
  agendas : agendaitem[] = []
  agendait : agendaitem = {agendaname : "", agendadocs : []}
  LoadMessage : any
  level: any = 0;
  i_parent: any = '';
  i_item_a: any = '';
  i_item_b: any = '';

  constructor(
    private sharedService: SharedService,
    public _location: Location,
    private apiManager: ApiManagerService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private router: Router
  ) {
    this._route.params.subscribe((params) => {
      if (params['meeting']) {
        this.initializePage(params['meeting']);
      } else this._location.back();
    });
  }

  ngOnInit(): void {
    this.LoadMessage = 'loading Agenda .....';
    const spinner = this.apiManager.startLoading(this.LoadMessage)
    console.log("meeting object is", this.meetingObj);
    this.createAgendaForm()
    setTimeout(() => {
      console.log("executing adding documents to agenda items after 4 seconds")
      this.adddocumentstoagendaitem()
    }, 4000);
    setTimeout(() => {
      console.log("waiting 4 seconds")
    }, 4000);

    this.getZoomDetails()
    this.apiManager.stopLoading(spinner)
  }

  ngAfterViewInit(){
    console.log("ng after view init")
    
    
  }
  createAgendaForm() {
    this.agendaForm = this.fb.group({
      meeting_id: [this.meetingObj?.id, [Validators.required]],
      items: this.fb.array([]),
    });
    this.addItem();
  }
  addItem() {
    (this.agendaForm.get('items') as FormArray).push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        document_names : [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
        level_a_items: this.fb.array([]),
      })
    );
  }
  publishmeeting(meetingObj){
    console.log("first obj", meetingObj)
    const spinner = this.apiManager.startLoading('Updating meeting..');
    meetingObj.isActive = true
    this.sendEmail = true;
    console.log(meetingObj)
    this.apiManager.updateMeetingStatus(meetingObj.id, meetingObj).subscribe({
      next: (res: any) => {
        this.apiManager.stopLoading(spinner);
        this.meetingObj = res
        console.log(this.meetingObj)
        this.router.navigate(['admin/meeting-page/draft']);
        this.openSnackBar('Meeting Published successfully', 'Close');
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        console.log(err);
        this.openSnackBar('Did not publish meeting', 'Close');
      },
    })
  }
  removeItem(i) {
    (this.agendaForm.get('items') as FormArray).removeAt(i);
    this.item = '';
  }

  initiateApi() {
    const request: any = this.setupData();
    if (request) {
      const spinner = this.apiManager.startLoading();
      const obs = forkJoin({
        c: request.req_minutes,
        d: request.req_rsvps,
        e: request.req_users,
        f: request.req_user_rsvp,
      });

      obs.subscribe({
        next: (res: any) => {
          this.apiManager.stopLoading(spinner);
          this.minutesObj = res.c;
          this.rsvps = res.d;
          this.users = res.e;
          this.usersRsvp = res.f;
        },
        error: (err) => {
          this.apiManager.stopLoading(spinner);
          console.log(err);
        },
      });
    }
  }

  setupData() {
    this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
    this.user_id = this.currentUser?.id;
    this.meeting_id = this.meetingObj?.id;
    const req_users = this.apiManager.getAllUsers();
    const req_agendas = this.apiManager.getMeetingAgendas(this.meeting_id);
    const req_rsvps = this.apiManager.getMeetingRsvp(this.meeting_id);
    const req_user_rsvp = this.apiManager.getUserRsvp(
      this.meeting_id,
      this.user_id
    );
    const req_minutes = this.apiManager.getMeetingMinute(this.meeting_id);

    this.getAgendaItems();
    
    return {
      req_agendas,
      req_minutes,
      req_rsvps,
      req_users,
      req_user_rsvp,
    };
  }

  EditInvitees(meeting){
    console.log("meeting", meeting)
    console.log("meeting", this.users)
    var data ={
      meeting: meeting,
      users : this.users
    } 
    const dialogRef = this.dialog.open(EditInviteesComponent, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        this.meetingObj = result
        const path = '/admin/meeting-draft-page';
        const param = {
          meeting: this.sharedService.encryptData(result),
        };
        this.sharedService.navigaTo(path, param);
      }
    });
  }


  initializePage(meeting: any) {
    this.meetingObj = this.sharedService.decryptData(meeting);
    if (this.meetingObj) {
      this.initiateApi();
    } else this._location.back();
  }

  getUserRsvp() {
    const data = {
      meeting_id: this.meetingObj.id,
      user_id: this.currentUser.id,
    };
    const spinner = this.apiManager.startLoading('Updating RSVP.....');
    this.apiManager.getUserRsvp(data.meeting_id, data.user_id).subscribe({
      next: (res) => {
        this.usersRsvp = res;
        this.apiManager.stopLoading(spinner);
      },
      error: (err) => this.apiManager.stopLoading(spinner),
    });
  }

  getAgendaItems() {
    const meeting_id = this.meetingObj.id;

    this.apiManager.getMeetingAgendas(meeting_id).subscribe({
      next: (res: any) => {
        // get keys in agenda docs
        this.agendakeys = Object.keys(res.agendadocuments) 
        this.agendadocuments = res.agendadocuments
        console.log("agenda documents are", this.agendadocuments)
        this.parseintojson(this.agendadocuments)
        this.items = this.convertItemsToHyrchy(res.agenda);
        
        console.log('items', this.items);
      },
      error: (err) => console.log(err),
    });
  }
  parseintojson(agendadocs){
    console.log("agenda keys are --", this.agendakeys)
    
    for(let x = 0; x<this.agendakeys.length; x+=1){
      console.log("agenda name is: key",this.agendakeys[x])
      console.log("key is",agendadocs[this.agendakeys[x]])
      this.agendait.agendaname = this.agendakeys[x]
      this.agendait.agendadocs = JSON.parse(agendadocs[this.agendakeys[x]])
      console.log("agenda item loop agendait", this.agendait)
      this.agendas.push(this.agendait)
      this.agendait = {agendaname : "", agendadocs : []}
    }

    console.log("agendas are", this.agendas)

  }
  getobj(name: any){
    console.log("agendas are ", this.agendas)
    const found = this.agendas.find((obj : any) => {
      return obj.agendaname === name;
    });
    console.log("found item is", found)
    return found
  }

  adddocumentstoagendaitem(){
    for(let x = 0; x<this.items.length; x+=1){
      console.log("agenda name",this.items[x].agenda_name)
      var getobbj = this.getobj(this.items[x].agenda_name)
      console.log("object received", getobbj)
      this.items[x].agendadocs = getobbj.agendadocs
    }
    console.log("items added field agendadocs",this.items)
  }


  // getAgendaItems() {
  //   const meeting_id = this.meetingObj.id;

  //   this.apiManager.getMeetingAgendas(meeting_id).subscribe({
  //     next: (res: any) => {
  //       console.log("response is ---", res)
  //       this.items = this.convertItemsToHyrchy(res.agenda);
  //       console.log('items', this.items);
  //     },
  //     error: (err) => console.log(err),
  //   });
  // }

  convertItemsToHyrchy(arr) {
    let map = {},
      node,
      res = [],
      i;

    for (i = 0; i < arr.length; i += 1) {
      map[arr[i].id] = i;
      arr[i].childreen = [];
    }
    for (i = 0; i < arr.length; i += 1) {
      node = arr[i];
      if (node.parent_item) {
        arr[map[node.parent_item]]?.childreen.push(node);
      } else {
        res.push(node);
      }
    }

    return res;
  }

  getUserIds(obj) {
    if (obj) {
      for (let i = 0; i <= obj.length; i++) {
        if (obj[i]?.id) {
          this.user_ids.push(obj[i]?.id);
        }
      }
      return this.user_ids;
    }
  }
  leterize(num) {
    var letter = String.fromCharCode(num + 64);
    return letter;
  }
  romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = '',
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  setupRemoteMeeting(meeting) {
    const dialogRef = this.dialog.open(DialogRemoteMeetingDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: meeting,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }

  joinMeeting() {
    let role = 0;
    if (this.currentUser.id == this.zoomObj?.created_by) {
      role = 1;
    }
    const data = {
      apiKey: 'wrcKqSnzSsSBqv5DQ6LygQ',
      meetingNumber: this.zoomObj['zoom_meeting_id'],
      password: this.zoomObj['zoom_meeting_password'],
      role: role,
    };
    console.log(data);
    this.sharedService.zoomObj.next(data);
    this.showZoomWindow = true;
  }

  viewMinutes(minutes: any) {
    const path = '/admin/minutes-page';
    const param = {
      minute: this.sharedService.encryptData(minutes[0]),
    };
    this.sharedService.navigaTo(path, param);
  }

  startMinutes(meeting: any) {
    const path = '/admin/minutes-creator-page';
    const param = {
      meeting: this.sharedService.encryptData(meeting),
    };
    this.sharedService.navigaTo(path, param);
  }

  openAgendaDocument(item: any) {
    console.log("item is -----", item.agenda_document)
    const data = {}
    data['doc_path'] = item.agenda_document
    this.apiManager.getDocumentsFromServer(data).subscribe({
      next: (res : any) => {
        console.log("response is", res);
        this.agendaDoc = res.file_blob
        this.viewAgendaDoc = true;
        // this.openSnackBar(
        //   'Agenda items attached to the meeting successfully. ',
        //   'Close'
        // );
      },
      error: (err) => {
        this.openSnackBar('Something went wrong. ', 'Close');
      },
    })
    // this.sharedService.doc_ref.next(item.doc_ref);
    // this.sharedService.DocumentToView.next(item.agenda_document);
  }
  openSingleAgendaDocument(item: any) {
    console.log("item is -----", item.agenda_document)
    const data = {}
    data['doc_path'] = item.agenda_document
    console.log("data is", data)
    // this.agendaDoc = item.agenda_document
    this.apiManager.getDocumentsFromServer(data).subscribe({
      next: (res : any) => {
        console.log("response is", res);
        this.agendaDoc = res.file_blob
        this.viewAgendaDoc = true;
        // this.openSnackBar(
        //   'Agenda items attached to the meeting successfully. ',
        //   'Close'
        // );
      },
      error: (err) => {
        this.openSnackBar('Something went wrong. ', 'Close');
      },
    })
    // this.sharedService.doc_ref.next(item.doc_ref);
    // this.sharedService.DocumentToView.next(item.agenda_document);
    
  }

  openEditMeetingDialog(meeting) {
    meeting['invitees'] = this.getUserIds(meeting['invitees']);
    const data = {
      meeting: meeting,
      users: this.users,
    };
    const dialogRef = this.dialog.open(DialogEditMeetingDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        const path = '/admin/meeting-view-page';
        const param = {
          meeting: this.sharedService.encryptData(result),
        };
        this.sharedService.navigaTo(path, param);
      }
    });
  }

  openBook(){
   
    this.router.navigate(['admin/meeting-openbook'], {state: {meeting_id : this.meetingObj.id}});
  }


  editItemDetailsDialog(i_item, level = 0, a_item_index, b_item_index): void {
    if (level == 0) {
      this.editItem = this.agendaForm.value.items[i_item];

      this.agendaFormUpdate = (<FormArray>this.agendaForm.get('items')).at(
        i_item
      );
    } else if (level == 1) {
      this.editItem =
        this.agendaForm.value.items[i_item].level_a_items[a_item_index];

      this.agendaFormUpdate = (
        (<FormArray>this.agendaForm.controls['items'])
          .at(i_item)
          .get('level_a_items') as FormArray
      ).at(a_item_index);
    } else if (level == 2) {
      this.editItem =
        this.agendaForm.value.items[i_item].level_a_items[
          a_item_index
        ].level_b_items[b_item_index];

      this.agendaFormUpdate = (
        (
          (<FormArray>this.agendaForm.controls['items'])
            .at(i_item)
            .get('level_a_items') as FormArray
        )
          .at(a_item_index)
          .get('level_b_items') as FormArray
      ).at(b_item_index);
    }
    const data = {
      item: this.editItem,
      users: this.meetingObj?.invitees,
    };

    const dialogRef = this.dialog.open(DialogEditAgendaDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result is",result)
        this.agendaFormUpdate.patchValue({
          agenda_name: result.agenda_name,
          agenda_description: result.agenda_description,
          agenda_document: result.agenda_document,
          document_names : result.document_names,
          permission: result.permission,
          presenters: result.presenters,
          guests: result.guests,
        });
      }
    });
  }

  submitAgendaItems(data) {
    console.log("data is", data)
    this.itemsJson = data;
    const spinner = this.apiManager.startLoading(
      'Saving meeting agenda items....'
    );
    this.apiManager.createAgenda(data).subscribe({
      
      next: (res : any) => {
        console.log("adding Agenda meeting response", res);
        this.items = this.convertItemsToHyrchy(res.data.agenda);
        this.apiManager.stopLoading(spinner);
        this.openSnackBar(
          'Agenda items attached to the meeting successfully. ',
          'Close'
        );
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        this.openSnackBar('Something went wrong. ', 'Close');
      },
    });
    this.agendaForm.reset();
    location.reload();
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  editListItemDetailsDialog(item) {
    const data = {
      users: this.users,
      agenda: item,
    };
  
    const dialogRef = this.dialog.open(DialogEditAgendaItemDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getAgendaItems();
      }
    });
  }


  
  // editItemDetailsDialog(item) {
  //   console.log("item is", item)
  //   const data = {
  //     users: this.users,
  //     agenda: item,
  //   };
  //   const dialogRef = this.dialog.open(DialogEditAgendaItemDialog, {
  //     restoreFocus: false,
  //     width: '500px',
  //     panelClass: ['animate__animated', 'animate__fadeInRight'],
  //     height: '100vh',
  //     position: {
  //       bottom: '0px',
  //       right: '0px',
  //     },
  //     data: data,
  //   });
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       this.getAgendaItems();
  //     }
  //   });
  // }
  openAttandanceDialog(rsvps) {
    const dialogRef = this.dialog.open(DialogAttandanceDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: rsvps,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  
  getZoomDetails() {
    this.apiManager.getZoomMeetingByMeetingId(this.meeting_id).subscribe({
      next: (res: any) => {
        this.zoomObj = res?.data;
        console.log('zoom', this.zoomObj);
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }
  
  setActiveItem(level, i_parent, i_item_a, i_item_b) {
    this.level = level;
    this.i_parent = i_parent;
    this.i_item_a = i_item_a;
    this.i_item_b = i_item_b;
  }
  addSubSection(level) {
    if (level == 1) {
      this.addSecondLevelItem(this.i_parent);
    } else if (level == 2) {
      this.addThirdLevelItem(this.i_parent, this.i_item_a);
    }
    this.level = 0;
  }

  deleteSection(level) {
    if (level == 1) {
      this.removeItem(this.i_parent);
    } else if (level == 2) {
      this.removeSecondLevelItem(this.i_parent, this.i_item_a);
    } else if (level == 3) {
      this.removeThirdLevelItem(this.i_parent, this.i_item_a, this.i_item_b);
    }
    this.level = 0;
  }

  // addItem() {
  //   (this.agendaForm.get('items') as FormArray).push(
  //     this.fb.group({
  //       agenda_name: ['', [Validators.required]],
  //       agenda_description: [''],
  //       agenda_document: [''],
  //       permission: [''],
  //       presenters: [''],
  //       guests: [''],
  //       level_a_items: this.fb.array([]),
  //     })
  //   );
  // }
  // removeItem(i) {
  //   (this.agendaForm.get('items') as FormArray).removeAt(i);
  //   this.item = '';
  // }

  addSecondLevelItem(i_parent) {
    console.log(i_parent);
    const options = (this.agendaForm.get('items') as FormArray).controls[
      i_parent
    ].get('level_a_items') as FormArray;
    console.log(options);

    options.push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
        level_b_items: this.fb.array([]),
      })
    );
  }

  removeSecondLevelItem(i_item, index) {
    (
      (<FormArray>this.agendaForm.controls['items'])
        .at(i_item)
        .get('level_a_items') as FormArray
    ).removeAt(index);
  }

  addThirdLevelItem(i_item, index) {
    const options = (
      (this.agendaForm.get('items') as FormArray).controls[i_item].get(
        'level_a_items'
      ) as FormArray
    ).controls[index].get('level_b_items') as FormArray;

    options.push(
      this.fb.group({
        agenda_name: ['', [Validators.required]],
        agenda_description: [''],
        agenda_document: [''],
        permission: [''],
        presenters: [''],
        guests: [''],
      })
    );
  }
  removeThirdLevelItem(i_item, index, i) {
    const item = (
      (<FormArray>this.agendaForm.controls['items'])
        .at(i_item)
        .get('level_a_items') as FormArray
    )
      .at(index)
      .get('level_b_items') as FormArray;
    item.removeAt(i);
  }

}
