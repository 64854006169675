import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {DialogEditAgendaItemDialog, agendaitem} from "../../meeting/meeting-view/meeting-view.component";
import {SharedService} from "../../shared.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import { PdfannotateComponent } from 'src/app/pdfannotate/pdfannotate.component';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface TextEditorAnnotation {
  annotationType ?: number;
  color ?: number[];
  fontSize ?: number;
  value ?: string;
  pageIndex ?: number;
  rect ?: number[];
  rotation ?: number;
  id ?: number;
}
export interface DrawEditorAnnotation {
  annotationType ?: number;
  color ?: number[];
  thickness ?: number;
  opacity ?: number;
  paths ?: any[];
  pageIndex ?: number;
  rect ?: number[];
  rotation ?: number;
  id ?: number;
}

@Component({
  selector: 'app-meeting-agenda-openbook',
  templateUrl: './meeting-agenda-openbook.component.html',
  styleUrls: ['./meeting-agenda-openbook.component.css']
})
export class MeetingAgendaOpenbookComponent implements OnInit {
  users: any = [];
  myannotations = []
  alldocannotations = []
  sessionStorage = window.sessionStorage;
  showZoomWindow = false;
  meeting_id: any = '';
  items: any = [];
  meetingObj: any = {};
  currentUser: any = {};
  viewAgendaDoc = false;
  show = true;
  pdfSrc: any;
  annotations: any;
  showToolbar = false;
  doc_ref : any;
  selectedTabIndex: any;
  blob : any;
  drawing : any;
  showmyannotations = false
  allComplete : false
  allann = false
  sharedann = false
  viewmyann = false
  agendadocuments : any[] = []
  hideann = false
  permittedusers : any[] = []
  selectedsharetouser: any
  annotationselectedforsharing = false
  selectedannotation : any
  selectedfullannotationtobeshared : any
  allannot : any
  agendakeys : any[] = []
  sharedwithannotations : any[] = []
  showannotationoptions : boolean = false
  sharedwithmeannotations : any[] = []
  agendas : agendaitem[] = []
  agendait : agendaitem = {agendaname : "", agendadocs : []}
  highlightdoc : any = ""
  docname : any = ""
  pagenumbers = {}
  pagenumberkeys = []
  disableannotation : boolean = false



  constructor( public _location: Location,
               private apiManager: ApiManagerService,
               private dialog: MatDialog,
               private router : Router,
               private sharedService: SharedService,
               private snackBar: MatSnackBar,
               private pdfViewerService: NgxExtendedPdfViewerService) {
    // console.log(this.router.getCurrentNavigation().extras.state.meeting_id);
    this.meeting_id = this.router.getCurrentNavigation().extras.state.meeting_id;
    console.log("meeting id is:", this.meeting_id);
    this.meetingObj = this.router.getCurrentNavigation().extras.state.meeting;
    if(!this.meetingObj){
      console.log("meeting is blank");
      this.apiManager.getActiveMeetings().subscribe({
        next: (res: any) => {
          console.log("getting meeting object", res);

          for (let item of res) {
            if (item.id === this.meeting_id) {
                console.log("this is the item", item.id)
                this.meetingObj = item;
                this.disableannotation = true;
            }
        }
          
        },
        error: (err) => console.log(err),
      })
    }
    
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.sessionStorage.getItem('profile'));
    setTimeout(() => {
    this.getAgendaItems();
    }, 2000);
    setTimeout(() => {
      this.getdocpagenumber()
  }, 2000);
    
  }

  getDocuments(id : any){
    console.log("getting items")
    
      this.apiManager.getMeetingDocuments(id).subscribe({
        next: (res: any) => {
          console.log(res);
          this.agendadocuments = res;
          console.log('agenda documents', this.agendadocuments);
        },
        error: (err) => console.log(err),
      })
      
    
  }

  getdocpagenumber(){
    console.log("items are ---",this.items)
    console.log("item keys are", this.agendakeys)
    
    let startpage
    let endpage
    for(let x = 0; x<this.items.length; x+=1){
      
      this.items[x].agendadocs
      for(let y = 0; y<this.items[x].agendadocs.length; y+=1){
        if(y == 0 && x == 0){
          this.pagenumbers["1"] = this.items[x].agendadocs[y].fields.document_name
          startpage = 1 
          this.docname = this.items[x].agendadocs[y].fields.document_name
          endpage = parseInt(this.items[x].agendadocs[y].fields.no_of_pages)
          console.log("start page is", startpage)
          console.log("end page is", endpage)
        }else{
          startpage = endpage+1
          this.pagenumbers[startpage] = this.items[x].agendadocs[y].fields.document_name
          endpage = endpage + parseInt(this.items[x].agendadocs[y].fields.no_of_pages)
        }
        // let docpages = parseInt(this.items[x].agendadocs[y].fields.no_of_pages)

        
      }

    }
    console.log("page numbers and their doc names",this.pagenumbers)
    this.pagenumberkeys = Object.keys(this.pagenumbers)
    console.log("page number keys", this.pagenumberkeys)
    
  }

  getAgendaItems() {

    console.log("our meeting object is", this.meetingObj)

    this.apiManager.getMeetingAgendas(this.meeting_id).subscribe({
      next: (res: any) => {
        console.log("response is ----", res)
        this.agendakeys = Object.keys(res.agendadocuments) 
        console.log("agenda keys are", this.agendakeys)
        this.agendadocuments = res.agendadocuments
        console.log("agenda documents are",this.agendadocuments)
        this.parseintojson(this.agendadocuments)
        this.items = this.convertItemsToHyrchy(res.agenda);
        this.adddocumentstoagendaitem()
        console.log('items', this.items);
      },
      error: (err) => console.log(err),
    });
    
    // this.getDocuments();
    setTimeout(() => {
      console.log("opening agenda document")
      const data = {}
      data['doc_path'] = this.meetingObj.merged_agenda_document;
      this.apiManager.getDocumentsFromServer(data).subscribe({
        next: (res: any) => {
          console.log("response getting document for openbook", res)
          this.pdfSrc = res.file_blob
          this.sharedService.DocumentToView.next(this.pdfSrc);
        },
        error: (err) => console.log(err),
      })  
      // this.sharedService.doc_ref.next(file.doc_ref);
      
      // this.viewAgendaDoc = true;
    }, 2000);
    
    this.highlightdoc = "highlight-doc"
    
  }
  openagendadoc(file){
    console.log("opened agenda item doc", file)
  }
  downloadagendadoc(file){
    console.log("downloading document", file)
    const data = {}
    data['doc_path'] = file
    this.apiManager.getDocumentsFromServer(data).subscribe({
      next: (res: any) => {
        file = res.file_blob
      },
      error: (err) => console.log(err),
    })

    setTimeout(() => {
      if(file){
        console.log("blob is ----"+file+" bytes")
        let filename = "download.pdf"
        let a = document.createElement("a")
        a.download=filename
        a.href = file
        a.click()
      }else{
        console.log("no blob")
      }
      }, 2000);
    
  }

  parseintojson(agendadocs){
    
    for(let x = 0; x<this.agendakeys.length; x+=1){
      console.log("key is",agendadocs[this.agendakeys[x]])
      this.agendait.agendaname = this.agendakeys[x]
      this.agendait.agendadocs = JSON.parse(agendadocs[this.agendakeys[x]])
      this.agendas.push(this.agendait)
      this.agendait = {agendaname : "", agendadocs : []}
    }

    console.log("agendas are", this.agendas)

  }
  getobj(name: any){
    const found = this.agendas.find((obj : any) => {
      return obj.agendaname === name;
    });
    return found
  }

  adddocumentstoagendaitem(){
    for(let x = 0; x<this.items.length; x+=1){
      var getobbj = this.getobj(this.items[x].agenda_name)
      console.log("object received", getobbj)
      this.items[x].agendadocs = getobbj.agendadocs
    }
    console.log("items added field agendadocs",this.items)
  }

  // getpermittedusers(){
  //   console.log("Items are---", this.items)
  //   for(let i = 0; i < this.items.length; i += 1 ){
  //     console.log("single items", this.items[i])
  //     const usersp : any[] = this.items[i].permission
  //     for(let x = 0; x< usersp.length; x+=1){
  //       this.permittedusers.push(usersp[x])
  //     }
  //   }
  //   console.log("permitted users", this.permittedusers)
  // }

  convertItemsToHyrchy(arr) {
    let map = {},
      node,
      res = [],
      i;

    for (i = 0; i < arr.length; i += 1) {
      map[arr[i].id] = i;
      arr[i].childreen = [];
    }
    for (i = 0; i < arr.length; i += 1) {
      node = arr[i];
      if (node.parent_item) {
        arr[map[node.parent_item]]?.childreen.push(node);
      } else {
        res.push(node);
      }
    }

    return res;
  }

  romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = '',
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  

  public async downloadpdfasis(): Promise<void> {
    this.selectedTabIndex = 2;
    this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
    // const annotation = await this.pdfViewerService.getSerializedAnnotations()
    console.log("our blob is -----"+ this.blob)
    if(this.blob){
      console.log("blob is ----"+this.blob.size+" bytes")
      let filename = "esignature.pdf"
      let a = document.createElement("a")
      a.download=filename
      a.href = window.URL.createObjectURL(this.blob)
      a.click()
    }else{
      console.log("no blob")
    }
    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.blob));
  }

  getsharedannotations(){
    console.log("getting shared annotations ")
    console.log("all doc annotations length", this.alldocannotations.length)
    for(let x =0 ; x< this.alldocannotations.length; x+=1){
      if(this.alldocannotations[x].xfdf_string.hasOwnProperty('shared_with')){
        console.log("annotation is -----",this.alldocannotations[x].xfdf_string)
        for(let y = 0; y< this.alldocannotations[x].xfdf_string.shared_with.length; y+=1){
          console.log("shared_with",this.alldocannotations[x].xfdf_string.shared_with[y].user)
          console.log("shared_by", this.alldocannotations[x].xfdf_string.shared_with[y].person_initiating_sharing)
         let z = {
            annotation : this.alldocannotations[x].xfdf_string,
            shared_with : this.alldocannotations[x].xfdf_string.shared_with[y].user,
            person_initiating_sharing : this.alldocannotations[x].xfdf_string.shared_with[y].person_initiating_sharing,
          }
          this.sharedwithannotations.push(z)
        }
    }
  }
  console.log("shared annotations are ---",this.sharedwithannotations)
  }
  getsharedwithmeannotations(){
    for(let x =0 ; x< this.sharedwithannotations.length; x+=1){
      if(this.sharedwithannotations[x].shared_with.id == this.currentUser.id){
        this.sharedwithmeannotations.push(this.sharedwithannotations[x])
      }

    }

  }

  displayannotation(event : any){
    this.removeEditors()
    console.log("display annotation event is", event)
    if (event.annotationType == 3){
      this.drawing = {
        annotationType: 3,
        color: event.color,
        fontSize: event.fontSize,
        value: event.value,
        pageIndex: event.pageIndex,
        rect: event.rect,
        rotation: event.rotation,
      };
    }
    if (event.annotationType == 15){
      this.drawing= {
        annotationType : event.annotationType,
        color : event.color,
        thickness: event.thickness,
        opacity : event.opacity,
        paths : event.paths,
        pageIndex: event.pageIndex,
        rect: event.rect,
        rotation: event.rotation,
        id: event.id,
      };
    }
    this.pdfViewerService.addEditorAnnotation(this.drawing)
    this.scroll(event.pageIndex+1,"0%")
    
  }


  onselectchange(annotation : any ){
    // let n = 0
    // n = n+1
    this.removeEditors()
    this.selectedfullannotationtobeshared = annotation
    console.log("full annotation", annotation)
    let event = annotation.xfdf_string
    // this.selectedannotation = event
    // console.log("event", event)
    // for (let x = 0; x < event.length; x++) {
      // console.log('Index/Loop No: ', [x]);
    if (event.annotationType == 3){
      this.drawing = {
        annotationType: 3,
        color: event.color,
        fontSize: event.fontSize,
        value: event.value,
        pageIndex: event.pageIndex,
        rect: event.rect,
        rotation: event.rotation,
      };
    }
    if (event.annotationType == 15){
      this.drawing= {
        annotationType : event.annotationType,
        color : event.color,
        thickness: event.thickness,
        opacity : event.opacity,
        paths : event.paths,
        pageIndex: event.pageIndex,
        rect: event.rect,
        rotation: event.rotation,
        id: event.id,
      };
    }
    this.pdfViewerService.addEditorAnnotation(this.drawing)
    console.log("page index is ", event.pageIndex+1)
    this.scroll(event.pageIndex+1,"0%")
    this.annotationselectedforsharing = true
    

    // console.log("event is -----" + event[0].annotationType)
   

    // console.log("event color is --"+event[0])
    // console.log("event color is --"+event1)
    // const x = 400*Math.random();
    // const y = 350+500*Math.random();
    // const drawing_2: any = {
    //   annotationType: 15,
    //   color: [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255)],
    //   thickness: Math.random()*10,
    //   opacity: 1,
    //   paths: [
    //     {
    //       bezier: [x+0.5, y, x+0.5, y+44, x+44, y+66, x+88, y+44],
    //       points: [x+0.5, y, x+0.5, y+44],
    //     },
    //   ],
    //   pageIndex: 0,
    //   rect: [x, y, x+100, y+66],
    //   rotation: 0,
    // };
    // const drawing: any = {
    //   annotationType: 3,
    //   color: event[0].color,
    //   fontSize: event[0].fontSize,
    //   value: event[0].value,
    //   pageIndex: 0,
    //   rect: event[0].rect,
    //   rotation: event[0].rotation,
    // };
    // console.log("drawing is --------" + drawing)
    // const drawing3:any = {
    //   annotationType: 13,
    //   bitmapId : "image_65cb35da-cd42-47e6-bf46-162428f3ab1c_0",
    //   pageIndex : 0,
    //   rect : [
    //     262.5159484863281,
    //     729.4371856689453,
    //     332.4815734863281,
    //     799.4028106689453
    //   ],
    //   rotation : 0,
    //   isSvg : false
    // }
    // this.pdfViewerService.addEditorAnnotation(drawing)
    // this.pdfViewerService.addEditorAnnotation(drawing_2)
    // this.pdfViewerService.addEditorAnnotation(drawing3)
  }

  randint(low:number, max?:number) {
    return Math.floor(Math.random() * 10) % (max ?? low) + (max ? low : 0);
  }
  closeagendadoc(){
    this.showannotationoptions = false
    this.viewAgendaDoc = false
  }

  annotatedoc(){
    this.showToolbar = true
    this.removeEditors()
    this.showannotationoptions = false
  }

  public scroll(pageNumber: number, top: number | string): void {
    this.pdfViewerService.scrollPageIntoView(pageNumber, {top});
  }

  public async exportAnnotations(): Promise<void>{
    this.annotations = await this.pdfViewerService.getSerializedAnnotations()
    console.log("annotations are -----",this.annotations)
    this.annotations.forEach( (element) => {
      if(!element.id){
        element.id = this.randint(10, 10000);
      }
      
    });
    const data = {
      xfdf_string: this.annotations,
      doc_ref: this.doc_ref,
      user: this.currentUser,
    };

    this.apiManager.saveAnnotsStringToServer(data).subscribe({
      next: (res : any) => {
        console.log("annotations saved to server",res);
        // this.myannotations = res.annotations;
        this.openSnackBar("Annotation saved successfully","CLOSE")
        return
        
      },
      error: (err) => console.log(err),
    });

  }

  getmyannotations(){
    console.log("current user is ", this.currentUser)
    console.log("getting my annotations")
    console.log("all doc annotations length", this.alldocannotations.length)
    console.log("my annotations before pushing", this.myannotations)
    this.myannotations = []
    for(let x = 0; x < this.alldocannotations.length; x+=1){
      if(this.alldocannotations[x].annoted_by.id == this.currentUser.id){
        this.myannotations.push(this.alldocannotations[x])
      }
    }
    console.log("my annotations are", this.myannotations)

  }
  viewpdf(){
    this.showannotationoptions = true
    this.showToolbar = false
    this.removeEditors()

    this.apiManager.getAnnotationsFromServer(this.doc_ref).subscribe({
      next: (res : any) => {
        console.log("all doc annotations",res);
        this.alldocannotations = res;
        this.showmyannotations = true;
        this.getsharedannotations()
        this.getmyannotations()
      },
      error: (err) => console.log(err),
    });

    
  }

  

  // public async export(): Promise<void> {
  //   this.selectedTabIndex = 2;
  //   this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
  //   // const annotation = await this.pdfViewerService.getSerializedAnnotations()
  //   console.log("our blob is -----"+ this.blob)
  //   if(this.blob){
  //     console.log("blob is ----"+this.blob.size+" bytes")
  //     let filename = "esignature.pdf"
  //     let a = document.createElement("a")
  //     a.download=filename
  //     a.href = window.URL.createObjectURL(this.blob)
  //     a.click()
  //   }else{
  //     console.log("no blob")
  //   }
  //   // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.blob));
  // }

 
  openAgendaDocument(file: any) {
    console.log("item is ", file)
    this.getDocuments(file.id)
    const data = {}
    data['doc_path'] = file.agenda_document;
    this.apiManager.getDocumentsFromServer(data).subscribe({
      next: (res : any) => {
        console.log(res);
        this.pdfSrc = res.file_blob
        this.doc_ref = file.doc_ref;
        this.sharedService.doc_ref.next(file.doc_ref);
        this.sharedService.DocumentToView.next(this.pdfSrc);
        this.viewAgendaDoc = true;
        this.permittedusers = file.permission
        this.viewAgendaDoc = true
        this.showToolbar = true
      },
      error: (err) => console.log(err),
    })
    

    // this.apiManager.getAnnotationsFromServer(this.doc_ref).subscribe({
    //   next: (res : any) => {
    //     console.log(res);
        
    //     this.showmyannotations = true
    //   },
    //   error: (err) => console.log(err),
    // });
}



onEvent(page : any, event : any){
  console.log("page change is", page)
  console.log("event is", event)
  if(this.pagenumberkeys.includes(event.toString())){
    console.log("changing docname")
    this.docname = this.pagenumbers[event]
    console.log("document name is", this.docname)
  }

}


shareannotations(){
  if(!this.selectedsharetouser){
    this.openSnackBar("please select user to share to", "CLOSE")
    return
  }
  if(!this.selectedfullannotationtobeshared){
    this.openSnackBar("please select annotation to share", "CLOSE")
    return
  }
  const data : any = {
    user : this.selectedsharetouser,
    person_initiating_sharing : this.currentUser,
  }
  console.log("selectedsharetouser", this.selectedsharetouser)
  console.log("selectedannotation", this.selectedfullannotationtobeshared)
  // updateAnnotsStringToServer(annot_id, data) 
  this.apiManager.updateAnnotsStringToServer(this.selectedfullannotationtobeshared.id,data).subscribe({
    next: (res : any) => {
      console.log(res);
      this.openSnackBar("Annotation shared successfully","CLOSE")
        return
      // this.myannotations = res;
      // this.showmyannotations = true
    },
    error: (err) => console.log(err),
  });
  
}


// openannotate(){

//   console.log("annotation started");
//   const data = {
//     url: this.pdfSrc,
//   };
//   const anotateref = this.dialog.open(PdfannotateComponent, {
//     restoreFocus: false,
//     width: '800px',
//     panelClass: ['animate__animated', 'animate__fadeInRight'],
//     height: '100vh',
//     position: {
//       bottom: '0px',
//       right: '0px',
//       left:'0px'
//     },
//     data: data,
//   });
//   anotateref.afterClosed().subscribe((result: any) => {
//     if (result) {
//       this.getAgendaItems();
//     }
//   });
// }

  // openAgendaDocument(documentObj) {
  //   this.pdfSrc = documentObj.document;
  //   console.log('pdfviewer');
  //   console.log(documentObj);
  //   this.sharedService.doc_ref.next(documentObj?.doc_ref);
  //   this.sharedService.DocumentToView.next(documentObj.agenda_document);
  //   this.viewAgendaDoc = true;
  // }

  editItemDetailsDialog(item) {
    const data = {
      users: this.users,
      agenda: item,
    };
    const dialogRef = this.dialog.open(DialogEditAgendaItemDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getAgendaItems();
      }
    });
  }

  leterize(num) {
    var letter = String.fromCharCode(num + 64);
    return letter;
  }


  public removeEditors(): void {
    this.pdfViewerService.removeEditorAnnotations();
  }
  
  public removeTextEditors(): void {
    const filter = (serial: any) => serial.annotationType === 3;
    this.pdfViewerService.removeEditorAnnotations(filter);
  }
  
  public removeDrawingEditors(): void {
    const filter = (serial: any) => serial.annotationType === 15;
    this.pdfViewerService.removeEditorAnnotations(filter);
  }

  allannotations(event : any){
    console.log(event)
    if(event == true ){
      this.sharedann = true
      this.viewmyann = true
      this.hideann = true
      console.log("all annotations",this.myannotations)

      this.allannot = this.myannotations 

      for( let x = 0; x < this.allannot.length; x+=1){
        let event = this.allannot[x].xfdf_string
        if (event.annotationType == 3){
          this.drawing = {
            annotationType: 3,
            color: event.color,
            fontSize: event.fontSize,
            value: event.value,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
          };
        }
        if (event.annotationType == 15){
          this.drawing= {
            annotationType : event.annotationType,
            color : event.color,
            thickness: event.thickness,
            opacity : event.opacity,
            paths : event.paths,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
            id: event.id,
          };
        }
        this.pdfViewerService.addEditorAnnotation(this.drawing)
      }
    }else {
      this.allann = false
      this.sharedann = false
      this.viewmyann = false
      this.hideann = false
      this.removeEditors()

    }
    // allann
    

  }

  sharedannotations(event : any){
    console.log(event)
    if(event == true ){
    this.allann = true
    this.sharedann = false
    this.viewmyann = true
    this.hideann = true

    this.allannot = this.myannotations 

      for( let x = 0; x < this.allannot.length; x+=1){
        let event = this.allannot[x].xfdf_string
        console.log(event.hasOwnProperty('shared_with'))
        if(event.hasOwnProperty('shared_with')){
          console.log("shared annotation with shared with field", event)
        if (event.annotationType == 3){
          this.drawing = {
            annotationType: 3,
            color: event.color,
            fontSize: event.fontSize,
            value: event.value,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
          };
        }
        if (event.annotationType == 15){
          this.drawing= {
            annotationType : event.annotationType,
            color : event.color,
            thickness: event.thickness,
            opacity : event.opacity,
            paths : event.paths,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
            id: event.id,
          };
        }
        this.pdfViewerService.addEditorAnnotation(this.drawing)
      }
    }
    }else {
    this.allann = false
    this.sharedann = false
    this.viewmyann = false
    this.hideann = false
    this.removeEditors()
  }
  }

  viewmyannotations(event : any){
    console.log(event)
    if(event == true ){
    this.allann = true
    this.sharedann = true
    this.viewmyann = false
    this.hideann = true

    this.allannot = this.myannotations 

      for( let x = 0; x < this.allannot.length; x+=1){
        let event = this.allannot[x].xfdf_string
        if (event.annotationType == 3){
          this.drawing = {
            annotationType: 3,
            color: event.color,
            fontSize: event.fontSize,
            value: event.value,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
          };
        }
        if (event.annotationType == 15){
          this.drawing= {
            annotationType : event.annotationType,
            color : event.color,
            thickness: event.thickness,
            opacity : event.opacity,
            paths : event.paths,
            pageIndex: event.pageIndex,
            rect: event.rect,
            rotation: event.rotation,
            id: event.id,
          };
        }
        this.pdfViewerService.addEditorAnnotation(this.drawing)
      }
    }else {
    this.allann = false
    this.sharedann = false
    this.viewmyann = false
    this.hideann = false
    this.removeEditors()
  }

  }

  hidennotations(event : any){
    console.log(event)
    if(event == true ){
    this.allann = true
    this.sharedann = true
    this.viewmyann = true
    this.hideann = false
    this.removeEditors()
    }else {
    this.allann = false
    this.sharedann = false
    this.viewmyann = false
    this.hideann = true
  }

  }

  getsharedannotationsbyuser(event : any){
    console.log("event is ---", event)

  }

  usertosharewith( user : any  ){
    this.selectedsharetouser = user.value
  }
  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

}
